var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":function($event){return _vm.submitForm(_vm.newFlow)}}},[_c('tab-content',{attrs:{"title":"Flux","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-avatar',{attrs:{"src":"" + _vm.publicPath + "images/flux/" + "" + (_vm.newFlow.logo),"size":"104px","rounded":""}})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"mb-1"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.newFlow.name)+" ")])]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Logo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Logo","label-for":"Logo","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"logo","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.fluxImageOptions},model:{value:(_vm.newFlow.logo),callback:function ($$v) {_vm.$set(_vm.newFlow, "logo", $$v)},expression:"newFlow.logo"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nom","label-for":"nom"}},[_c('validation-provider',{attrs:{"name":"Nom","vid":"Nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"MyFlow"},model:{value:(_vm.newFlow.name),callback:function ($$v) {_vm.$set(_vm.newFlow, "name", $$v)},expression:"newFlow.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description","vid":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":"My custom description"},model:{value:(_vm.newFlow.description),callback:function ($$v) {_vm.$set(_vm.newFlow, "description", $$v)},expression:"newFlow.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Origine du flux","label-for":"Origine du flux"}},[_c('validation-provider',{attrs:{"name":"Origine du flux","vid":"Origine du flux","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"flow_origin","state":errors.length > 0 ? false : null,"placeholder":"flow_origin"},model:{value:(_vm.newFlow.flow_origin),callback:function ($$v) {_vm.$set(_vm.newFlow, "flow_origin", $$v)},expression:"newFlow.flow_origin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nom du job (talend)","label-for":"Nom du job"}},[_c('validation-provider',{attrs:{"name":"Nom du job","vid":"Nom du job","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"job_name","state":errors.length > 0 ? false : null,"placeholder":"job_name"},model:{value:(_vm.newFlow.job_name),callback:function ($$v) {_vm.$set(_vm.newFlow, "job_name", $$v)},expression:"newFlow.job_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Type Offres","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type Offres","label-for":"Type Offres","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"type_offers","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.typeOffersOptions,"reduce":function (val) { return val.value; },"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.newFlow.type_offers),callback:function ($$v) {_vm.$set(_vm.newFlow, "type_offers", $$v)},expression:"newFlow.type_offers"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Format","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Format","label-for":"Format","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"format","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.formatOptions,"reduce":function (val) { return val.value; },"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.newFlow.format),callback:function ($$v) {_vm.$set(_vm.newFlow, "format", $$v)},expression:"newFlow.format"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Statut","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Statut","label-for":"Statut","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"status","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":function (val) { return val.value; },"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.newFlow.status),callback:function ($$v) {_vm.$set(_vm.newFlow, "status", $$v)},expression:"newFlow.status"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }