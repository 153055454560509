import store from "@/store";

import { useRouter } from "@core/utils/utils";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useFlowsCreate() {
  const { router } = useRouter();
  const toast = useToast();

  const createFlow = (newFlow) => {
    store
      .dispatch("app-flow/createFlow", { newFlow })
      .then(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Form Submitted",
              icon: "EditIcon",
              variant: "success",
            },
          },
          { position: "top-center" }
        );
        // redirect to list page
        router.push({ name: "flow-list" });
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error creating Flow",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  return {
    createFlow,
  };
}
