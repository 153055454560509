import Vue from "vue";
import * as Api from "../../axios-generated/backoffice/";
// @ts-ignore
import axios from "@axios";

const FLOW_API = new Api.FlowsApi(
  undefined,
  Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL,
  axios
);

const CONTACTS_API = new Api.ContactsApi(
  undefined,
  Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL,
  axios
);

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFlows(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        FLOW_API.findFlows(
          queryParams.sortBy,
          queryParams.perPage,
          queryParams.page,
          queryParams.sortDesc
        )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchFlowByID(ctx, { flowID }) {
      return new Promise((resolve, reject) => {
        FLOW_API.findFlowByID(flowID)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    findFlowsStatistics() {
      return new Promise((resolve, reject) => {
        FLOW_API.findFlowsStatistics()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    findJobsByFlowJobName(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        FLOW_API.findJobsByFlowJobName(
          queryParams.code,
          queryParams.perPage,
          queryParams.page
        )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createFlow(ctx, { newFlow }) {
      return new Promise((resolve, reject) => {
        FLOW_API.createFlow(newFlow)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editFlow(ctx, { editFlow }) {
      return new Promise((resolve, reject) => {
        FLOW_API.updateFlow(editFlow.ID, editFlow)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    findContactsByFlowID(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        FLOW_API.findContactsByFlowID(
          queryParams.flowID,
          queryParams.sortBy,
          queryParams.perPage,
          queryParams.page,
          queryParams.sortDesc
        )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // CONTACTS
    createContact(ctx, { newContact }) {
      return new Promise((resolve, reject) => {
        CONTACTS_API.createContact(newContact)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editContact(ctx, { updateContact }) {
      return new Promise((resolve, reject) => {
        CONTACTS_API.updateContact(updateContact.ID, updateContact)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    findContactByID(ctx, { contactID }) {
      return new Promise((resolve, reject) => {
        CONTACTS_API.findContactByID(contactID)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteContactByID(ctx, { contactID }) {
      return new Promise((resolve, reject) => {
        CONTACTS_API.deleteContactByID(contactID)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
