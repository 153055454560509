<template>
  <b-row class="mt-2">
    <b-col cols="12">
      <flow-create-form-wizard :new-flow="newFlow" />
    </b-col>
  </b-row>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol } from "bootstrap-vue";
import FlowCreateFormWizard from "./FlowCreateFormWizard.vue";
import flowStoreModule from "../flowStoreModule";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    FlowCreateFormWizard,
  },
  mixins: [PageBoxed],
  setup() {
    const newFlow = ref({});

    const FLOW_APP_STORE_MODULE_NAME = "app-flow";

    // Register module
    if (!store.hasModule(FLOW_APP_STORE_MODULE_NAME))
      store.registerModule(FLOW_APP_STORE_MODULE_NAME, flowStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FLOW_APP_STORE_MODULE_NAME))
        store.unregisterModule(FLOW_APP_STORE_MODULE_NAME);
    });

    return {
      newFlow,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
